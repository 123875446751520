.resetPassword {
    display: flex;
    width: 100vw;
    height: 100vh;

    .left {
        flex: 6;
    }

    .right {
        flex: 3;

        .container {
            background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
            display: flex;
            flex-direction: column;
            padding: 25px;
            height: 100vh;

            h1 {
                color: white;
            }

            h6 {
                color: white;
                font-weight: 400;
            }

            .description {
                margin-top: 100px;
            }

            .input {
                margin-top: 20px;
                a{
                    color: chartreuse;
                    font-size: 16px;
                    text-align: center;
                }
                input {
                    width: 100%;
                    border-radius: 8px;
                    height: 40px;
                    outline: none;
                    border: 2px solid #c4c4c4;
                    padding: 0 30px;
                    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
                }
            }

            .button {
                margin-top: 20px;

                button {
                    margin-top: 15px;
                    width: 100%;
                    height: 45px;
                    background-color: #276afb;
                    color: white;
                    border: none;
                    padding: 12px 64px;
                    font-size: 20px;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: all 0.3s ease-out;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }

                button:hover {
                    color: #fff;
                    background-color: #f00946;
                    transition: all 0.2s ease-out;
                }
            }
            .errorMessage{
                margin-top: 20px;
                a{
                    color: rgb(255, 0, 0);
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        .left {
            flex: none;
        }
        
        .right {
            flex: none;
            margin-top: 0;
            
            display: flex;
            height: 100vh;
            width: 100vw;
            align-items: center;
            justify-content: center;

            .container {
                display: flex;
                flex-direction: column;
                padding: 25px;
                width: 50%;
                height: 85%;
            }
        }
    }

    @media (max-width: 580px) {

        .right {

            .container {
                width: 60%;
            }
        }
    }

    @media (max-width: 480px) {
        .right {


            .container {
                padding: 15px;
                width: 70%;
                h1 {
                    font-size: 24px;
                }

                h6 {
                    font-size: 14px;
                }

                .description {
                    margin-top: 60px;
                }

                .input {
                    margin-top: 15px;

                    input {
                        height: 35px;
                        font-size: 14px;
                    }
                }

                .button {
                    margin-top: 15px;

                    button {
                        height: 40px;
                        font-size: 16px;
                        padding: 12px 48px;
                    }
                }
            }
        }
    }
}