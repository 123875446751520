*{
    margin: 0px;
    padding: 0%;
    box-sizing: border-box;
    /* overflow: hidden; */
    
}
.body{
    /* font-family: sans-serif; */
}
/* .blur {
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: #edd0ff;
    z-index: -9;
    top: -18%;
    left: 56%;
    filter: blur(72px);

} */