:root {
  /* --primaryColor:#0b2b66; */
  /* --primaryColor: #00014a; */

  --primaryColor: #00005b;
  /* --secondaryColor:#f2796e; */
  /* --secondaryColor: #e8f931;yellow */
  --secondaryColor:white  ;
  --thirdColor: #f00946;

  --primaryFontColor: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
