.directLandingPage {
    width: 80%;
    height: 50%;
    // background-color: rgb(197, 197, 209);
    padding: 35px 25px 35px 25px;
    display: flex;
    flex-direction: column;
    box-shadow: -2px -1px 19px -3px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: -2px -1px 19px -3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -2px -1px 19px -3px rgba(0, 0, 0, 0.1);
    margin: 10px 0px 0px 20px;
    border-radius: 10px;
    gap: 15px;
    h6{
        color: #ff0000;
        // font-style: italic;
    }

    &_lineOne {
        // background-color: blueviolet;
        max-width: 100%;
        min-height: 40%;
        // background-color: blueviolet;
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;

        &-title {
            min-width: 85%;

            h5 {
                font-size: 5vh;
                font-style: bold;
            }
        }
        &-toggleButton {
            max-width: 10%;
            // justify-content: space-between;
            // align-items: flex-end;

            width: 50%;
            height: 100%;
        }
    }

    &_form {
        width: 100%;

        &-inputBox {
            input {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                height: 50px;
                outline: none;
                border: 2px solid #c4c4c4;
                padding: 0 30px;
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
            }
        }
        &-toggleButton {
        }
    }
    &_button {
        button {
            margin-top: 15px;
            width: 100%;
            height: 50px;
            background-color: #276afb;
            color: white;
            font-size: 17px;
            border-radius: 4px;
            outline: none;
            cursor: pointer;
            padding: 12px 64px;
            font-size: 20px;
            transition: all 0.3s ease-out;
            color: white;
            border: none;
        }
    }
}

@media (max-width: 600px) {
    .directLandingPage {
        min-height: 70%;
        gap: 20px;
        padding: 10px;
        width: 90%;

        input {
            height: 50px;
        }
    }
}
